<template>
  <div>
    <div
      class="menu"
      style="margin-bottom: 20px;"
      ref="menu"
    >
      <el-menu
        mode="horizontal"
        :default-active="LastStr($route.path, $route.name)"
        @select="handleDirect"
      >
        <el-menu-item
          v-for="item in menus"
          :key="item.path"
          :index="item.path"
        >{{item.name}}</el-menu-item>
      </el-menu>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data () {
    const menus = [
      // { path: 'distributor', name: '库存预警' },
      { path: 'distribution', name: '物流配送' },
      // {path: 'distribute.relate', name: '运费模板'},
      // {path: 'withdrawal1', name: '货品单位'},
      // {path: 'withdrawal2', name: '审批设置'},
      // {path: 'withdrawal3', name: '表单样式'},
      { path: 'universallySetting', name: '通用设置' },
      { path: 'applySetting', name: '支付设置' },
      { path: 'storeSetting', name: '店铺设置' }
    ]
    return {
      menus,
      default: ''
    }
  },
  methods: {
    handleDirect (name) {
      this.$router.push({ name })
    },
    LastStr (str, name) {
      if (name === 'distribution' || name === 'expressage' || name === 'newExpressage' || name === 'takeTheir' || name === 'newTakeTheir') {
        return 'distribution'
      } else {
        console.log('name', name)
        return name
      }
    }
  }

}
</script>
